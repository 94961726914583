<h3>
	<ng-content></ng-content>
</h3>

<div class="end">
	<ng-content select="[end]"></ng-content>
</div>

<ng-container *ngIf="collapsible">
	<button
		class="icon-only"
		[class.rotate]="open"
		type="button"
		(click)="toggle()"
	>
		<i class="fa-solid fa-chevron-down"></i>
	</button>
</ng-container>
