import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DisplayTheme, Theme } from '@array-app/shared/types';
import {
	getAutoTheme,
	getDisplayTheme,
	getTheme,
	hexToHSL,
	setTheme
} from '@array-app/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@array-app/frontend/authentication';

@Component({
	standalone: true,
	selector: 'app-theme-picker',
	imports: [
		TranslateModule,
		MatButtonToggleModule,
		MatTooltipModule,
		CommonModule
	],
	templateUrl: 'theme-picker.component.html'
})
export class ThemePickerComponent implements OnInit {
	/**
	 * `true` if the auto option should be shown to the user
	 */
	@Input() showAuto = true;
	/**
	 * Emits when the theme is changed
	 */
	@Output() themeChange = new EventEmitter<Theme>();
	/**
	 * The theme currently on display. 'Auto' cannot be displayed so
	 * it is excluded in this value
	 */
	displayTheme!: DisplayTheme;
	/**
	 * The real theme value that is currently set
	 */
	theme!: Theme;

	constructor(private readonly authService: AuthService) {}

	ngOnInit() {
		this.theme = getTheme();
		if (this.theme === 'auto') {
			this.displayTheme = getAutoTheme();

			if (!this.showAuto) {
				this.theme = this.displayTheme;
			}
		} else {
			this.displayTheme = this.theme;
		}
	}

	/**
	 * Changes the app theme to the corresponding theme value
	 * @param theme The theme to change to
	 */
	onThemeChange(theme: Theme) {
		setTheme(theme);
		this.setOrganizationBranding();
		this.themeChange.emit(theme);
	}

	/**
	 * Sets the organization branding for when the theme changes
	 */
	setOrganizationBranding() {
		const org = this.authService.organization$?.value;
		if (org) {
			const displayTheme = getDisplayTheme();
			const hsl = hexToHSL(org[displayTheme].primaryColor);
			document.body.style.setProperty(
				'--color-primary-hs',
				`${hsl.h}, ${hsl.s}%`
			);
			document.body.style.setProperty('--color-primary-l', `${hsl.l}%`);
			document.body.style.setProperty(
				'--color-secondary',
				org[displayTheme].secondaryColor
			);
			document.body.style.setProperty(
				'--color-tertiary',
				org[displayTheme].tertiaryColor
			);
		}
	}
}
