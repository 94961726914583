import { NgComponentOutlet, NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { CardHeaderComponent } from './card-header/card-header.component';

@Component({
	standalone: true,
	selector: 'app-card',
	imports: [NgIf, NgComponentOutlet, CardHeaderComponent],
	templateUrl: 'card.component.html',
	styleUrls: ['card.component.scss']
})
export class CardComponent {
	/**
	 * The text to be displayed as the header of the card.
	 */
	@Input() header!: string;

	/**
	 * `true` if the user should be able to collapse the card
	 */
	@Input() collapsible = true;

	/**
	 * `true` if the footer area of the card should be visible
	 */
	@Input() showFooter = false;

	/**
	 * `true` if the header should not be rendered with this card.
	 */
	@HostBinding('class.no-header')
	@Input()
	hideHeader = false;

	/**
	 * `true` if the card should have a max height and become scrollable afterwards
	 */
	@HostBinding('class.scrollable')
	@Input()
	scrollable = false;

	/**
	 * `true` if the card is currently open. Only applies when `collapsible`
	 * is `true`.
	 */
	@HostBinding('class.open')
	open = true;

	/**
	 * Toggles the open property value to show and hide the contents of this card
	 */
	toggleOpen() {
		this.open = !this.open;
	}
}
