<mat-button-toggle-group
	name="theme"
	[attr.aria-label]="'brand.theme' | translate"
	[value]="theme"
	(change)="onThemeChange($event.value)"
>
	<mat-button-toggle value="light" [matTooltip]="'brand.light' | translate">
		<i class="fa-solid fa-sun"></i>
	</mat-button-toggle>
	<mat-button-toggle value="dark" [matTooltip]="'brand.dark' | translate">
		<i class="fa-solid fa-moon"></i>
	</mat-button-toggle>
	<mat-button-toggle
		*ngIf="showAuto"
		value="auto"
		[matTooltip]="'brand.auto' | translate"
	>
		<i class="fa-solid fa-wand-magic-sparkles"></i>
	</mat-button-toggle>
</mat-button-toggle-group>
