import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-card-header',
	imports: [CommonModule],
	templateUrl: 'card-header.component.html',
	styleUrls: ['card-header.component.scss']
})
export class CardHeaderComponent {
	@HostBinding('class.collapsible')
	@Input()
	collapsible = true;

	@HostBinding('class.open')
	@Input()
	open = true;

	@Output() toggleOpen = new EventEmitter();

	toggle() {
		this.toggleOpen.emit();
	}
}
