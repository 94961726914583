<app-card-header
	*ngIf="!hideHeader"
	[collapsible]="collapsible"
	[open]="open"
	(toggleOpen)="toggleOpen()"
	[title]="header"
>
	{{ header }}

	<ng-content end select="[end]"></ng-content>
</app-card-header>

<div *ngIf="open" class="card-content">
	<ng-content></ng-content>
</div>

<div *ngIf="showFooter && open" class="card-footer">
	<ng-content select="[footer]"></ng-content>
</div>
